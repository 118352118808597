.ecom-page .section > .contents {
  padding-left: 30px;
  padding-right: 30px;
}
/* Section: Header */
header.ecom-header img {
  height: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Section: First with video */
.section.background-gradient {
  background: linear-gradient(rgb(56, 122, 216), rgb(163, 0, 205)) 0% 0% / auto
    repeat scroll padding-box border-box rgb(255, 255, 255);
  color: white;
}
.section.background-gradient > .contents {
  padding-top: 50px;
  padding-bottom: 80px;
}
.shop-key-points {
  padding: 30px 50px;
}
.shop-key-points > div img {
  height: 20px;
  margin-top: 12px;
}
.iframe {
  width: calc(100vw - 60px);
  height: 50vw;
}

/* Section: Clients */
.ecom-page .section.clients {
  background-color: rgb(255, 240, 155);
}
.ecom-page .section.clients > .contents {
  padding-top: 20px;
  padding-bottom: 20px;
}
/* Section: Features */
.section.features .contents {
  padding-top: 70px;
  padding-bottom: 70px;
  gap: 100px;
}
.feature-grid {
  display: grid;
  gap: 1rem;
}
.feature-grid img {
  width: 100%;
}
.other-features {
  background-color: rgb(249, 249, 249);
  border-radius: 16px;
  padding: 20px;
}
.other-features li:not(:last-child) {
  margin-bottom: 12px;
}

/* Section: Testimonials */
.ecom-page .section.testimonials {
  background-color: rgb(246, 221, 247);
}
.ecom-page .section.testimonials .contents {
  padding: 50px;
}

/* Section: FAQs */
.section.faqs > .contents {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Section: Why Landknock */
.section.why > .contents {
  padding: 100px 60px;
}
.section.why > .contents li:not(:last-child) {
  margin-bottom: 10px;
}
.section.why > .contents .selected-text {
  background-color: rgb(0, 108, 255);
  color: white;
}
/* Section: Guarantee */
.section.guarantee {
  background-color: rgb(232, 237, 243);
  border-bottom: 7px solid rgb(0, 98, 235);
}
.section.guarantee .contents {
  padding: 50px;
  text-align: center;
}
.section.guarantee .contents img {
  width: 180px;
}
.section.guarantee .contents .text-contents {
  line-height: 1.5;
}
/* Section: Pricing */
.section.ecom-pricing .contents {
  padding: 50px 10px;
}
.counters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.counters div {
  width: 66px;
  height: 56px;
  padding: 8px;
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  border-radius: 8px;
  background-color: rgb(229, 229, 229);
}

/* *********************************** */
/*  Display > Mobile: Medium and Large */
/* *********************************** */
@media only screen and (min-width: 768px) {
  /* Section: Features */
  .feature-grid {
    grid-template-rows: minmax(0px, auto);
    grid-template-areas:
      "left right"
      "left right";
  }
  .feature-grid .image.right {
    grid-area: right;
  }
  .feature-grid .image.left {
    grid-area: left;
  }
  .feature-grid img {
    width: 486px;
  }
  .other-features .list {
    display: flex;
    flex-direction: row;
  }

  /* Section: Why Landknock */
  .section.why > .contents {
    padding: 100px 120px;
  }

  /* Section: Guarantee */
  .section.guarantee .contents {
    flex-direction: row !important;
    padding: 50px 150px;
    text-align: left;
  }

  /* Section: Pricing */
  .ecom-page .package > .package-contents {
    flex-direction: column !important;
  }
}

/* *********************** */
/* Mobile - Medium Display */
/* *********************** */
@media only screen and (min-width: 999px) {
  .iframe {
    width: 979px;
    height: 576px;
  }
}
