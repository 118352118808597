.landing-page .section .contents {
  padding: 25px 66px;
  color: rgb(85, 95, 109);
  font-size: 13px;
  line-height: 1.8;
}
/* Section Header */
.section.landing-header .contents {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 20px;
}
.section.landing-header img {
  height: 27px;
}
/* Section: Top Text */
.section.landing-top-text .contents {
  display: inline;
  font-size: 14px;
  line-height: 2;
  text-align: left;
}
/* Section: Software Cards */
/* .section.softwares > .contents {} */
.software-cards-container {
  display: grid;
  gap: 50px;
  justify-content: space-between;
}
.software-card {
  position: relative;
  background-color: rgb(232, 237, 243);
  padding: 20px;
  border-radius: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
}
.software-card .title {
  text-align: center;
  grid-column: 1/3;
}
.software-card img {
  height: 85px;
}
.software-card .link {
  width: 100%;
  margin-top: 20px;
  grid-column: 1/3;
  display: flex;
  justify-content: center;
}
.software-card .link a {
  position: absolute;
  bottom: -22px;
}
/* Section: Clients */
.section.clients .contents {
  justify-content: center;
}
.section.clients .contents img {
  width: 70vw;
}

/* Section: Testimonials */
.section.testimonials .title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}
.section.testimonials .comments {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.section.testimonials .comments > .comment {
  border: lightgray solid 1px;
  border-radius: 16px;
  padding: 20px;
  display: grid;
  gap: 30px;
}
.section.testimonials .comments > .comment .client img {
  height: 41px;
}
.section.testimonials .comments > .comment .client .identity {
  font-size: 14px;
  line-height: 1;
}

/* Section: Button */
.section.landing-button .contents {
  display: flex;
  justify-content: center;
}
.section.landing-button .contents .link-button {
  font-size: 18px;
  line-height: 1;
  height: 50px;
  width: 100%;
}
/* Section: Contect */
.section.landing-contact .contents {
  background-color: rgb(232, 237, 243);
  border-radius: 16px;
  padding: 30px;
  margin: 40px 30px 0px 30px;
  width: calc(100% - 60px);
  display: grid;
  gap: 30px;
}
.section.landing-contact .contents > .grid {
  display: grid;
  justify-content: center;
  align-items: center;
}
.section.landing-contact .contents img {
  justify-self: center;
  height: 30px;
}
/* Section: Footer */
.section.landing-footer .contents {
  text-align: center;
  padding: 10px;
}
/* **************** */
/* Display > Mobile */
/* **************** */
@media only screen and (min-width: 768px) {
  .landing-page .section .contents {
    padding-left: 100px;
    padding-right: 100px;
  }
  /* Section: Header */
  .section.landing-header > .contents {
    padding-top: 35px;
    padding-bottom: 25px;
  }

  /* Section: Top Text */
  .section.landing-top-text .contents {
    font-size: 22px;
    line-height: 2;
  }

  /* Section: Software Cards */
  .software-cards-container {
    grid-template-columns: auto auto;
  }
  .software-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: unset;
    width: 347px;
    height: 432px;
    padding: 20px;
    font-size: 16px;
  }
  .software-card img {
    height: 163px;
  }
  .software-card .link {
    margin-top: 0;
  }
  .software-card .link a {
    position: unset;
  }
  .section.softwares > .contents > .text {
    font-size: 16px;
  }
  /* Section: Clients */
  .section.clients .contents img {
    width: 516px;
  }
  /* Section: Testimonials */
  .section.testimonials .comments {
    flex-direction: row;
    justify-content: center;
  }
  .section.testimonials .comments > .comment {
    width: 300px;
  }
  /* Section: Button */
  .section.landing-button .contents .link-button {
    width: 70%;
  }
  /* Section: Contect */
  .section.landing-contact .contents {
    width: 920px;
  }
  .section.landing-contact .contents {
    grid-template-columns: 1fr 1fr;
  }
  .section.landing-contact .contents > .grid {
    display: grid;
  }
}
