/* .payment-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.lk-text {
  color: white;
}
.lk-button {
  padding: 0px 20px 0px 20px;
  height: 50px;
  border-radius: 10px;
}
.lk-button:hover {
  color: #007bff;
  background-color: #fff;
  border-color: #007bff;
}
*/

.payment-form {
  background-color: #4b4b4b;
  color: white;
  padding: 20px;
  border-radius: 16px;
}
.payment-form form {
  font-size: 14px;
  display: grid;
  gap: 15px;
}

@media (min-width: 768px) {
  .payment-form {
    width: 400px;
  }
}
