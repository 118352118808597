.delivery-page .section .contents {
  padding-left: 30px;
  padding-right: 30px;
}

/* Section: Header */
.section.delivery-header .contents {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.section.delivery-header img {
  height: 25px;
}
.section.delivery-header .link-button {
  border-radius: 6px;
  height: 30px;
  width: 120px;
  font-size: 14px;
}
/* Section: Top Section */
.section.delivery-top {
  border-bottom: 3px solid #0062eb;
}
.section.delivery-top .contents .title {
  font-size: 30px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  padding: 10px 40px;
  padding-bottom: 50px;
}
.section.delivery-top .contents img {
  height: 260px;
}
.section.delivery-top .link-button {
  border-radius: 6px;
  height: unset;
  width: unset;
  padding: 15px;
}
/* Section: Video */
.section.delivery-video-section .contents {
  padding-top: 80px;
  padding-bottom: 80px;
}
.section.delivery-video-section .contents iframe {
  width: 100%;
  height: calc(50vw - 58px);
}
/* Section: Clients */
.delivery-page .section.clients {
  padding-bottom: 50px;
}
/* Section: Delivery Key Points */
.section.delivery-key-points {
  background-color: #2b3a4e;
  color: white;
}
.section.delivery-key-points .contents {
  padding-top: 60px;
  padding-bottom: 30px;
  display: grid;
  gap: 50px;
}
.section.delivery-key-points .contents .letter .title {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 50px;
}
.section.delivery-key-points .contents .letter > * {
  margin-left: 35px;
}
.point {
  display: flex;
  gap: 10px;
}
.point img {
  width: 25px;
  height: 20px;
}
.section.delivery-key-points .link-button {
  border-radius: 6px;
  height: unset;
  padding: 15px;
}
.link-button.second:hover {
  background-color: #0059df;
  color: white;
}

/* Section: Chanllenges */
.section.delivery-challenges .contents {
  padding-top: 150px;
  padding-bottom: 150px;
}
.section.delivery-challenges .contents img {
  width: 240px;
}

/* Section: Solution */
.section.delivery-solution .contents {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section.delivery-solution .contents img.order {
  width: 100%;
}
.section.delivery-solution .contents .points {
  padding: 50px 0;
}

/* Section: Features */
.section.delivery-features .contents {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Section: Text Section */
.section.text-section {
  background-color: #e8edf3;
}
.section.text-section .contents {
  padding: 50px;
  font-size: 18px;
}

/* Section: Award */
.section.awards-section .contents {
  padding-top: 80px;
  padding-bottom: 80px;
}
.awards {
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.award {
  border: 1px solid lightgray;
  border-radius: 16px;
  padding: 15px 20px;
  width: 256px;
  height: 430px;
  display: grid;
  grid-template-rows: max-content;
}
.award img {
  width: 196px;
  height: 256px;
  object-fit: cover;
  margin-bottom: 15px;
  justify-self: center;
}

/* Section: Recommendation */
.section.recommendation {
  background-color: #f1f1f1;
}
.section.recommendation .contents {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Section: Coverage */
.section.coverage .contents {
  padding-top: 150px;
  padding-bottom: 150px;
}
.section.coverage .contents > div {
  flex: 1;
}
.section.coverage .contents img {
  height: 300px;
}

/* Section: Testimonials */
.delivery-page .section.testimonials .contents .title {
  font-size: 26px;
  margin-bottom: 60px;
}

/* Section: News */
.section.news .contents {
  padding-bottom: 220px;
}

/* Section: Pricing */
.section.pricing .contents {
  padding-top: 150px;
  padding-bottom: 50px;
}
.delivery-packages {
  padding: 50px 20px;
}

/* Section: Guarantee */
.section.delivery-guarantee .contents {
  padding-top: 50px;
  padding-bottom: 100px;
}
.section.delivery-guarantee .contents img {
  width: 160px;
}

/* Section: Payment */
.section.delivery-payment .contents {
  padding-top: 50px;
  padding-bottom: 50px;
}
/* **************** */
/* Display > Mobile */
/* **************** */
@media only screen and (min-width: 768px) {
  .iframe-mini {
    height: 220px;
  }
  /* Section: Top Section */
  .section.delivery-top .contents .title {
    font-size: 36px;
  }
  /* Section: Video */
  .section.delivery-video-section .contents iframe {
    height: 550px;
  }

  /* Section: Delivery Key Points */
  .section.delivery-key-points .contents > * {
    margin: 0px 120px;
  }

  /* Section: Solution */
  .section.delivery-solution .contents .points {
    padding: 50px 100px;
  }

  /* Section: Text Section */
  .section.text-section .contents {
    padding: 50px 150px;
    font-size: 26px;
  }

  /* Section: Awards */
  .section.awards-section .contents .title {
    padding: 0 150px;
  }

  /* Section: Testimonials */
  .delivery-page .section.testimonials .contents .title {
    padding: 0 200px;
  }

  /* Section: FAQs */
  .delivery-page .section.faqs .contents {
    padding-left: 120px;
    padding-right: 120px;
  }
}
