html {
  font-family: Helvetica, Arial, "Helvetica Neue", sans-serif;
}
a {
  text-decoration: none;
  color: unset;
}
a:hover {
  color: rgb(0, 106, 255);
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section > .contents {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.title-big {
  font-size: 32px;
}
.title-medium {
  font-size: 24px;
}
.title-small {
  font-size: 16px;
}
.text-contents {
  font-size: 16px;
  line-height: 2;
}
.lk-color-blue {
  color: rgb(0, 106, 255);
}
.lk-color-red {
  color: #f37272;
}
.lk-color-gray {
  color: rgb(85, 95, 109);
}
.lk-color-lightgray {
  color: #aeaeae;
}
.lk-bg-yellow {
  background-color: #fcda18;
  line-height: 1.2;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.lh-1 {
  line-height: 1;
}
.lh-15 {
  line-height: 1.5;
}
.lh-18 {
  line-height: 1.8;
}
.lh-2 {
  line-height: 2;
}
/* ******************** */
/* Mobile First Styling */
/* ******************** */
.flex-1 {
  flex: 1;
}
/* ***************************************** */
/*  Display > Mobile: Medium & Large Display */
/* ***************************************** */
@media only screen and (min-width: 768px) {
}

/* ************************************* */
/* Display > Medium: Large Display Only  */
/*************************************** */
@media only screen and (min-width: 999px) {
  .section > .contents {
    width: 980px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* ********** */
/* Common CSS */
/* ********** */
.link-button {
  background-color: #0062eb;
  color: white;
  height: 44px;
  width: 80%;
  border-radius: 20px;
  font-size: 16px;

  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.link-button:hover {
  background-color: transparent;
  color: #0062eb;
  border: 1px solid #0062eb;
}
/* Package */
.iframe-mini {
  width: 100%;
  height: 35vw;
}
.package {
  color: #4c5359;
  border: solid 2px lightgray;
  border-radius: 16px;
  padding: 16px;
  padding-top: 0;
  display: grid;
}
.package .package-name {
  justify-self: center;
  min-width: 200px;
  height: 42px;
  padding: 0 16px;
  margin-top: -21px;
  margin-bottom: 16px;

  background-color: #e8edf3;
  border-radius: 20px;
}
/* Policy */
.policy-page {
  padding-bottom: 50px;
}
/* Footer */
.footer-common {
  color: #8d8b8b;
}
.footer-common > .section.footer-contact {
  background-color: #1d1d1d;
}
.footer-common > .section.footer-contact > .contents {
  padding: 20px 70px;
}
.footer-common > .section.footer-contact img {
  width: 100px;
  height: 80px;
}
.footer-common > .section.footer-copyright {
  background-color: #0f0f0f;
}
.footer-common > .section.footer-copyright > .contents {
  padding: 10px 70px;
}
.footer-common > .section.footer-copyright > .contents img {
  height: 15px;
}
